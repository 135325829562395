import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
    Grid,
    Typography,
    Link,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PurpleAccentHero from "../components/PurpleAccentHero"
import { strings } from "../utils/strings"
import CookiesSnackbar from "../components/CookiesSnackbar"
import DiscoverMoreBanner from "../components/DiscoverMoreBanner"

const useStyles = makeStyles({
    bodyHeader: {
        fontWeight: "bold",
        marginTop: 30
    },
    gridSection: {
        marginTop: 30
    },
    formBox: {
        marginLeft: 20,
        marginRight: 20
    },
    formBoxEnd: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 24
    },
    infoBold: {
        fontWeight: "bold"
    },
    headerTransform: {
        textTransform: "uppercase"
    },
    tableTypographyNote: {
        marginTop: 10
    },
    table: {
        paddingLeft: 5,
        paddingRight: 5
    }
})

const PrivacyPage: FC = () => {
    const classes = useStyles()

    const data = useStaticQuery(graphql`
        query {
            desktopHeroImage: file(relativePath: { eq: "index/home-hero-desktop.jpg" }) {
                childImageSharp {
                    fluid(quality: 75, maxWidth: 2160) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            mobileHeroImage: file(relativePath: { eq: "index/home-hero-mobile.jpg" }) {
                childImageSharp {
                    fluid(quality: 75, maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    const createData = (cookie, expires, purpose) => {
        return { cookie, expires, purpose }
    }
    const rows = [
        createData("current-pathname*", "n/a", "Current page user is viewing within the Nulia ACM application"),
        createData("last-pathname*", "n/a", "Previous page user viewed within the Nulia ACM application"),
        createData("how-cookie-message", "n/a", "Has user seen cookies opt-in message"),
        createData("seen-pathway-tutorial", "n/a", "Has the user seen the tutorial"),
        createData("adal.*", "n/a", "Active directory authorization token"),
        createData("blob*", "n/a", "Access token needed to view Nulia content"),
        createData("pdfjs.history*", "n/a", "PDF viewing information (which page number, scaling factor)"),
        createData(
            "Google Analytics Session Information",
            "24 hours",
            "Collect data such as IP address and browser type. Used to identify where and how consumers access the Nulia ACM"
        ),
        createData(
            "Google Analytics Unique Identifier",
            "2 years",
            "Used to identify a single user on a device and map their use of the Nulia ACM from one session to another"
        ),
        createData(
            "Google Analytics Request",
            "1 minute",
            "Used to prevent a user from rapidly calling the google analytics endpoint. Error mitigation and DDoS protection."
        )
    ]

    return (
        <Layout>
            <SEO title="Privacy" />
            <DiscoverMoreBanner/>
            <PurpleAccentHero
                desktopImage={data.desktopHeroImage.childImageSharp.fluid}
                mobileImage={data.mobileHeroImage.childImageSharp.fluid}
                alt="Man smiling in front of his computer in an open office environment"
            />
            <section>
                <Typography
                    variant="h4"
                    align="center"
                    color="textSecondary"
                    className={classes.bodyHeader}
                    gutterBottom={true}
                    paragraph={true}
                >
                    {strings?.nuliaCookiesAndPrivacyPolicyPageTitle}
                </Typography>

                <Grid container={true} direction="column" className={classes.gridSection} spacing={1}>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#informationWeGatherFromYou" underline="none">
                            {strings?.informationWeGatherFromYou}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#whyWeCollectHaveAccessToYourInformation" underline="none">
                            {strings?.whyWeCollectHaveAccessToYourInformation}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#cookiesAndIPAddresses" underline="none">
                            {strings?.cookiesAndIPAddresses}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#areThereCasesWhereWeMayUse" underline="none">
                            {strings?.areThereCasesWhereWeMayUse}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#whatRightsDoYouHave" underline="none">
                            {strings?.whatRightsDoYouHave}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#withdrawalOfConsent" underline="none">
                            {strings?.withdrawalOfConsent}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#whoWeShareYourInformationWith" underline="none">
                            {strings?.whoWeShareYourInformationWith}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#thirdPartyWebsites" underline="none">
                            {strings?.thirdPartyWebsites}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#howDoWeProtectYourPersonalInformation" underline="none">
                            {strings?.howDoWeProtectYourPersonalInformation}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#breachReporting" underline="none">
                            {strings?.breachReporting}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#retentionOfPersonalData" underline="none">
                            {strings?.retentionOfPersonalData}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#offensiveContent" underline="none">
                            {strings?.offensiveContent}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#amendmentsToPrivacyPolicy" underline="none">
                            {strings?.amendmentsToPrivacyPolicy}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#howToContactUs" underline="none">
                            {strings?.howToContactUs}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Link href="#EuUsDataPrivacyFramework" underline="none">
                            {strings?.dataPrivacyFramework}
                        </Link>
                    </Grid>
                </Grid>

                <Grid container={true} direction="column" className={classes.gridSection} spacing={1}>
                    <Grid item={true} className={classes.formBox}>
                        <Typography
                            variant="subtitle1"
                            align="center"
                            color="textSecondary"
                            paragraph={true}
                            gutterBottom={true}
                        >
                            {strings?.nuliaCookiesAndPrivacyPolicy}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="subtitle1" color="textSecondary" paragraph={true}>
                            {strings?.introduction}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.introduction1}
                            <Link href="https://nuliaworks.com" target="_blank">
                                {strings?.introduction2}
                            </Link>
                            {strings?.introduction3}
                            <span className={classes.infoBold}>{strings?.introduction4}</span>
                            {strings?.introduction5}
                            <span className={classes.infoBold}>{strings?.introduction6}</span>
                            {strings?.introduction7}
                            <span className={classes.infoBold}>{strings?.introduction8}</span>
                            {strings?.introduction9}
                            <span className={classes.infoBold}>{strings?.introduction10}</span>
                            {strings?.introduction9}
                            <span className={classes.infoBold}>{strings?.introduction11}</span>
                            {strings?.introduction12}
                            <span className={classes.infoBold}>{strings?.introduction13}</span>
                            {strings?.introduction14}
                            <span className={classes.infoBold}>{strings?.introduction15}</span>
                            {strings?.introduction16}
                            <span className={classes.infoBold}>{strings?.introduction17}</span>
                            {strings?.introduction18}
                            <span className={classes.infoBold}>{strings?.introduction19}</span>
                            {strings?.introduction20}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.introduction21}
                            <span className={classes.infoBold}>{strings?.introduction22}</span>
                            {strings?.introduction23}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.introduction24}
                            <span className={classes.infoBold}>{strings?.introduction25}</span>
                            {strings?.introduction26}
                            <span className={classes.infoBold}>{strings?.introduction27}</span>
                            {strings?.introduction28}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.introduction29}
                            <span className={classes.infoBold}>{strings?.introduction30}</span>
                            {strings?.introduction31}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.introduction32}
                            <span className={classes.infoBold}>{strings?.introduction33}</span>
                            {strings?.introduction34}
                            <span className={classes.infoBold}>{strings?.introduction35}</span>
                            {strings?.introduction36}
                            <span className={classes.infoBold}>{strings?.introduction37}</span>
                            {strings?.introduction38}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.introduction40}
                            <Link href="https://www.privacyshield.gov/" target="_blank">
                                {strings?.introduction41}
                            </Link>
                            {strings?.introduction42}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.introduction39}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="informationWeGatherFromYou">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.informationWeGatherFromYou}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.informationWeGatherFromYou1}
                            <Link href="http://www.dataprotection.ie/" target="_blank">
                                {strings?.informationWeGatherFromYou1a}
                            </Link>
                            {strings?.informationWeGatherFromYou1b}
                            <span className={classes.infoBold}>{strings?.informationWeGatherFromYou1c}</span>
                            {strings?.informationWeGatherFromYou1d}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.informationWeGatherFromYou2}
                            <span className={classes.infoBold}>{strings?.informationWeGatherFromYou2a}</span>
                            {strings?.informationWeGatherFromYou2b}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.informationWeGatherFromYou3}
                            <span className={classes.infoBold}>{strings?.informationWeGatherFromYou3a}</span>
                            {strings?.informationWeGatherFromYou3b}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.informationWeGatherFromYou4}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.informationWeGatherFromYou5}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.informationWeGatherFromYou6}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="whyWeCollectHaveAccessToYourInformation">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.whyWeCollectHaveAccessToYourInformation}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whyWeCollectHaveAccessToYourInformation1}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whyWeCollectHaveAccessToYourInformation2}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whyWeCollectHaveAccessToYourInformation3}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="cookiesAndIPAddresses">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.cookiesAndIPAddresses}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.cookiesAndIPAddresses1}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.cookiesAndIPAddresses2}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.cookiesAndIPAddresses3}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.cookiesAndIPAddresses4}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.cookiesAndIPAddresses5}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.cookiesAndIPAddresses6}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.cookiesAndIPAddresses7}
                        </Typography>

                        <TableContainer component={Paper} className={classes.table}>
                            <Table size="small" aria-label="a dense table" padding="none">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Cookie</TableCell>
                                        <TableCell align="right">Expires</TableCell>
                                        <TableCell align="right">Purpose</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.cookie}>
                                            <TableCell component="th" scope="row">
                                                {row.cookie}
                                            </TableCell>
                                            <TableCell align="left">{row.expires}</TableCell>
                                            <TableCell align="right">{row.purpose}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Typography
                            variant="body2"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.tableTypographyNote}
                        >
                            {strings?.cookiesAndIPAddressesTableNote}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.cookiesAndIPAddresses8}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="areThereCasesWhereWeMayUse">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.areThereCasesWhereWeMayUse}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.areThereCasesWhereWeMayUse1}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.areThereCasesWhereWeMayUse1a}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.areThereCasesWhereWeMayUse1b}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.areThereCasesWhereWeMayUse1c}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="whatRightsDoYouHave">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.whatRightsDoYouHave}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave1}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave1a}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave1b}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave1c}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave1d}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave1e}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave1f}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave1g}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave1h}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave2}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave3Num}
                            <span className={classes.infoBold}>{strings?.whatRightsDoYouHave3Text}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave3Header}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave3a}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave3b}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave3c}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave3d}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave3e}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave3f}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave3g}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave3h}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave3i}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave3j}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave4Num}
                            <span className={classes.infoBold}>{strings?.whatRightsDoYouHave4}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave4a}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave4aa}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave4ab}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave4ac}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave4b}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave4c}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave5Num}
                            <span className={classes.infoBold}>{strings?.whatRightsDoYouHave5Text}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave5a}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave5b}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave5ba}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave5bb}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave5c}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave6Num}
                            <span className={classes.infoBold}>{strings?.whatRightsDoYouHave6Text}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave6a}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave6b}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave7Num}
                            <span className={classes.infoBold}>{strings?.whatRightsDoYouHave7Text}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave7a}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave7aa}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave7ab}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave7ac}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave7ad}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave7b}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave7ba}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave7bb}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave7bc}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave7bd}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave8Num}
                            <span className={classes.infoBold}>{strings?.whatRightsDoYouHave8Text}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave8a}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave8aa}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave8ab}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave9Num}
                            <span className={classes.infoBold}>{strings?.whatRightsDoYouHave9Text}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave9a}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave9aa}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave9ab}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave9ac}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave9ad}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave9ae}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave9b}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave10Num}
                            <span className={classes.infoBold}>{strings?.whatRightsDoYouHave10Text}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave10a}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave10b}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave10c}
                            <Link href="https://www.jamsadr.com/" target="_blank">
                                {strings?.whatRightsDoYouHave10d}
                            </Link>
                            {strings?.whatRightsDoYouHave10e}
                            <Link href="https://www.jamsadr.com/" target="_blank">
                                {strings?.whatRightsDoYouHave10d}
                            </Link>
                            {strings?.whatRightsDoYouHave10f}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whatRightsDoYouHave10g}
                        </Typography>
                        <Link href="https://www.privacyshield.gov/article?id=ANNEX-I-introduction" target="_blank">
                            {strings?.whatRightsDoYouHave10h}
                        </Link>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="withdrawalOfConsent">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.withdrawalOfConsent}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.withdrawalOfConsent1}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="whoWeShareYourInformationWith">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.whoWeShareYourInformationWith}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whoWeShareYourInformationWith1}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whoWeShareYourInformationWith2}
                            <sup>1</sup>
                            {strings?.whoWeShareYourInformationWith2a}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whoWeShareYourInformationWith3}'
                            <span className={classes.infoBold}>{strings?.whoWeShareYourInformationWith3a}</span>
                            {strings?.whoWeShareYourInformationWith3b}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.whoWeShareYourInformationWith4}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="thirdPartyWebsites">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.thirdPartyWebsites}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.thirdPartyWebsites1}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="howDoWeProtectYourPersonalInformation">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.howDoWeProtectYourPersonalInformation}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.howDoWeProtectYourPersonalInformation1}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.howDoWeProtectYourPersonalInformation2}
                        </Typography>
                        {/*<Typography variant="body2" color="textSecondary" paragraph={true}>*/}
                        {/*    {strings?.howDoWeProtectYourPersonalInformation3}*/}
                        {/*</Typography>*/}
                    </Grid>
                    <Grid item={true} className={classes.formBox} id="breachReporting">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.breachReporting}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.breachReporting1}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.breachReporting2}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.breachReporting2a}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.breachReporting2b}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.breachReporting2c}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="retentionOfPersonalData">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.retentionOfPersonalData}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.retentionOfPersonalData1}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.retentionOfPersonalData2}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="offensiveContent">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.offensiveContent}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.offensiveContent1}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.offensiveContent2}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="amendmentsToPrivacyPolicy">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.amendmentsToPrivacyPolicy}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.amendmentsToPrivacyPolicy1}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="howToContactUs">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.howToContactUs}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.howToContactUs1}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox} id="EuUsDataPrivacyFramework">
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            paragraph={true}
                            className={classes.headerTransform}
                        >
                            {strings?.dataPrivacyFramework}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            {strings?.dataPrivacyFramework1}
                            <Link href="https://www.dataprivacyframework.gov/" target="_blank">
                                {strings?.dataPrivacyFramework2}
                            </Link>
                            {strings?.dataPrivacyFramework3}
                        </Typography>
                    </Grid>
                </Grid>
            </section>
            <CookiesSnackbar />
        </Layout>
    )
}

export default PrivacyPage
